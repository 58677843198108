.awards {
  width: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    padding: size(20px) size(20px) 0;
    margin: 0;

    @include vp-767 {
      padding: size(20px) 0 0;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        .awards__name,
        .awards__year {
          color: $color-light-slate-blue;
        }
      }
    }
  }

  &__box {
    padding: 0 size(20px) 0 0;
  }

  &__name {
    font-size: size(40px);
    line-height: size(45px);
    font-weight: 400;
    margin: 0;
    transition: all $trans-default;

    @include vp-1023 {
      font-size: size(30px);
      line-height: size(35px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &__place {
    color: $color-pink-swan;
    font-size: size(40px);
    line-height: size(45px);
    margin: 0;

    @include vp-1023 {
      font-size: size(30px);
      line-height: size(35px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &__year {
    font-size: size(40px);
    line-height: size(45px);
    transition: all $trans-default;

    @include vp-1023 {
      font-size: size(30px);
      line-height: size(35px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &__grid {
    display: grid;
    gap: size(20px);
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: size(40px) 0 size(20px) 0;
    padding: 0;

    @include vp-1023 {
      grid-template-columns: 1fr;
    }

    @include vp-767 {
      margin: size(20px) 0 0;
    }
  }

  &__grid-item {
    padding: size(20px);
    position: relative;
  }

  &__grid-top {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 0 0 size(20px) 0;
    position: relative;
  }

  &__grid-title {
    font-size: size(20px);
    font-weight: 400;
    line-height: size(25px);
    margin: 0;
    padding: 0 size(20px) 0 0;
  }

  &__grid-year {
    font-size: size(20px);
    line-height: size(25px);
  }

  &__grid-description {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);
    margin: 0;
    max-width: 80%;
    position: relative;

    @include vp-767 {
      max-width: 100%;
    }
  }

  &__shadow-link {
    background-color: $color-default-white;
    border-radius: size(20px);
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all $trans-default;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        box-shadow: 0 size(20px) size(40px) rgba(0, 0, 0, 0.05);
      }
    }

    &::before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
    }
  }
}
