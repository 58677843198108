.btn {
  background-color: $color-default-black;
  border-radius: size(20px);
  color: $color-default-white;
  display: inline-block;
  font-size: size(15px);
  line-height: size(20px);
  margin: 0;
  padding: size(10px) size(15px);
  transition: all $trans-default;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  border: none;

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  &--white {
    color: $color-default-black;
    background-color: $color-default-white;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      background-color: $color-light-slate-blue;
    }

    &--white:hover {
      background-color: $color-default-white;
    }
  }

  &--violet {
    background-color: $color-light-slate-blue;
    color: $color-default-white;
    cursor: pointer;
    border-radius: 40px;
    border: 0;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--orange {
    background-color: $color-carrot-orange;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--limeric {
    background-color: $color-limerick;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--royal-blue {
    background-color: $color-royal-blue;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--royal-blue-2 {
    background-color: $color-royal-blue-2;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--wild-watermelon {
    background-color: $color-wild-watermelon;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--mahogany {
    background-color: $color-mahogany;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--apple {
    background-color: $color-apple;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--dodger-blue {
    background-color: $color-dodger-blue;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--malachite {
    background-color: $color-malachite;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--alice-blue {
    background-color: $color-alice-blue;
    color: $color-default-black;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-default-white;
        background-color: $color-default-black;
      }
    }
  }

  &--salomie {
    background-color: $color-salomie;
    color: $color-default-black;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-default-white;
        background-color: $color-default-black;
      }
    }
  }

  &--black-rock {
    background-color: $color-black-rock;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--medium-aquamarine {
    background-color: $color-medium-aquamarine;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--egyptian-blue {
    background-color: $color-egyptian-blue;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }

  &--onahau {
    background-color: $color-onahau;
    color: $color-default-black;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-default-white;
        background-color: $color-default-black;
      }
    }
  }

  &--japonica {
    background-color: $color-japonica;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        background-color: $color-default-black;
      }
    }
  }
}
