.form-modal {
  @include vp-767 {
    height: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: size(15px);

    @include vp-767 {
      height: 100%;
    }
  }

  .custom-input__label {
    top: size(-8px);
  }

  .custom-input label input {
    line-height: 1;
  }

  .btn {
    min-height: size(65px);

    @include vp-767 {
      margin-top: auto;
    }
  }

  form {
    @include vp-767 {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__note {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);
    margin: size(15px) 0 0 0;
    text-align: center;
  }

  &__note-link {
    color: $color-pink-swan;
    text-decoration: underline;
    text-decoration-thickness: size(1px);
    text-underline-offset: size(4px);
    text-decoration-color: rgba(0, 0, 0, 0.15);
    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-light-slate-blue;
      }
    }
  }

  &__button {
    position: relative;

    &.is-loading {
      color: $color-transparent;
      background: $color-pink-swan;
      pointer-events: none;

      .form-modal__button-loader {
        display: block;
      }
    }

    &.is-success {
      color: $color-transparent;
      background: $color-emerald;
      pointer-events: none;

      .form-modal__button-success {
        display: block;
        fill: none;
        stroke: $color-default-white;
      }
    }
  }

  &__button-icon {
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
