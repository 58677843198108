.header {
  padding: size(20px) 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #f2f2f2;
  z-index: 20;

  //&:has(.main-nav.is-active) {
  //  width: 100vw;
  //}

  .container {
    padding: 0 size(20px);
  }

  &.no-eng {
    .header__language {
      display: none;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    font-weight: 600;
    flex-shrink: 0;
    line-height: size(20px);
    transition: all $trans-default;
    user-select: none;
    text-transform: lowercase;

    &.is-menu {
      border: size(1px) solid rgba(255, 255, 255, 0.15);
      color: $color-default-white;
      z-index: 25;
    }
  }

  &__language {
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;

    @include vp-1023 {
      border-color: rgba(255, 255, 255, 0.15);
      color: $color-default-white;
      display: inline-block;
      position: absolute;
      top: size(20px);
      right: size(80px);
      z-index: 20;

      &.dynamic-adaptive {
        display: none;
      }
    }
  }
}
