.inner-simple-block {
  padding: size(150px) 0 0;

  @include vp-1023 {
    padding: size(100px) 0 0;
  }

  @include vp-767 {
    padding: size(100px) 0 0;
  }

  &--no-padding {
    padding: 0;

    @include vp-767 {
      padding: 0;
    }
  }

  &--margin-top {
    margin: size(65px) 0 0;

    @include vp-767 {
      margin: size(40px) 0 0;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: minmax(size(420px), auto) minmax(auto, size(820px));
    grid-template-rows: auto;

    @include vp-1023 {
      grid-template-columns: auto;
    }
  }

  &__aside {
    grid-column: 1 / 2;

    @include vp-1279 {
      width: 100%;
    }

    @include vp-1023 {
      grid-column: auto;
      width: 100%;
      margin: 0 0 size(20px);
      padding: 0;

      .aside__text {
        max-width: unset;
      }
    }

    @include vp-767 {
      margin: 0 0 size(10px);
    }

    &--mobile-bottom {
      @include vp-1023 {
        grid-row: -1;
        margin: size(20px) 0 0;

        & p {
          font-size: size(20px);
          line-height: size(25px);
        }
      }

      @include vp-1023 {
        &.inner-simple-block__aside--margin-big {
          margin: size(40px) 0 0;
        }
      }

      @include vp-767 {
        margin: size(5px) 0 0;

        & p {
          font-size: size(15px);
          line-height: size(20px);
        }
      }
    }
  }

  &__description {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-column: 2 / 3;

    @include vp-1023 {
      grid-column: 1 / -1;
    }

    :last-child {
      margin-bottom: 0;
    }

    .inner-simple-box--margin-top-bottom {
      margin-top: size(40px);
    }

    &:first-of-type {
      .inner-simple-text:first-child {
        margin-top: 0!important;
      }
    }
  }

  &__box {
    margin: size(65px) 0 0;
    grid-column: 1 / -1;

    @include vp-1023 {
      grid-column: 1 / -1;
    }

    &--more-half {
      margin: size(40px) 0 0;
      grid-column: 2 / -1;
    }

    &--less-half {
      margin: size(40px) 0 0;
      grid-column: 1 / 2;
    }

    &--no-margin {
      margin: 0;
    }
  }

  &__picture {
    margin: size(65px) 0 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }

    &--no-margin {
      margin: 0;

      @include vp-1023 {
        margin: 0;
      }
    }
  }

  &__picture-text {
    margin: size(10px) 0;
    padding: 0 size(20px);
    font-size: size(15px);
    line-height: size(20px);
    color: $color-pink-swan;

    @include vp-767 {
      padding: 0;
    }
  }

  br.only-desktop {
    @include vp-1023 {
      display: none;
    }
  }

  br.only-tablet {
    display: none;

    @include vp-1023 {
      display: block;
    }

    @include vp-767 {
      display: none;
    }
  }

  br.only-mobile {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  .inner-simple-table:first-child {
    margin-top: 0;
  }
}
