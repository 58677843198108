@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "GraphikLC";

  font-display: swap;
  src:
    url("/fonts/Graphik-Regular.woff2") format("woff2"),
    url("/fonts/Graphik-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "GraphikLC";

  font-display: swap;
  src:
    url("/fonts/Graphik-Semibold.woff2") format("woff2"),
    url("/fonts/Graphik-Semibold.woff") format("woff");
}
