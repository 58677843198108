.inner-title {
  margin: 0 0 size(156px) 0;
  padding: size(67px) 0 0 0;

  @include vp-1023 {
    margin: 0 0 size(100px) 0;
    padding: size(60px) 0 0;
  }

  @include vp-767 {
    margin: 0 0 size(102px) 0;
    padding: size(41px) 0 0;
  }

  &--margin-short {
    @include vp-767 {
      margin: 0 0 size(48px) 0;
    }
  }

  &__text {
    font-size: size(60px);
    font-weight: 400;
    line-height: size(65px);
    margin: 0;
    padding: 0 0 0 size(20px);
    white-space: break-spaces;

    &.wrap {
      white-space: pre-line;
    }

    @include vp-1023 {
      font-size: size(50px);
      line-height: size(55px);
      max-width: unset;
      padding: 0;
    }

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
    }
  }

  .desktop-only {
    @include vp-767 {
      display: none;
    }
  }

  .mobile-only {
    display: none;

    @include vp-767 {
      display: block;
    }
  }
}
