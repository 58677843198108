.main-nav {
  flex-grow: 1;
  margin: 0 0 0 25.65%;

  @include vp-1023 {
    background-color: $color-default-black;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    opacity: 0;
    margin: 0;
    padding: size(100px) size(20px) size(20px);
    position: fixed;
    pointer-events: none;
    top: 0;
    transition: all $trans-default;
    right: 0;
    visibility: hidden;
    z-index: 20;
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
    visibility: visible;

    .main-nav__item {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp-1023 {
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
      padding: size(80px) 0 0;
    }
  }

  &__item {
    margin: 0 size(10px) 0 0;

    @include vp-1023 {
      margin: 0;
      opacity: 0;
      transition: all ease-out 0.15s;
      transform: translateX(size(-15px));
    }

    &:nth-child(n):not(:last-child) {
      @include vp-1023 {
        margin: 0 0 size(26px) 0;
      }
    }

    &:nth-child(n)[data-nav-item="desktop-last"] {
      margin: 0 size(10px) 0 0;
      order: 99;

      @include vp-1023 {
        order: initial;
        margin: 0 0 size(26px) 0;
      }
    }

    &[data-nav-item="desktop-first"] {
      margin-left: auto;
      order: 99;

      @include vp-1023 {
        order: unset;
      }
    }
  }

  &__link {
    color: $color-default-black;
    display: flex;
    user-select: none;

    &.link--violet {
      background-color: #a87dff;
      color: #fff;
      cursor: pointer;

      &:hover {
        background: black;
      }

      @include vp-1023 {
        background: black;
        color: #a87dff;
      }
    }

    &.is-active {
      background-color: $color-default-black;
      color: $color-default-white;
    }

    @include vp-1023 {
      color: $color-default-white;
      font-size: size(40px);
      line-height: size(45px);
      padding: 0;
    }
  }

  &__buttons {
    display: none;

    @include vp-1023 {
      align-items: center;
      display: flex;
    }
  }

  &__buttons-request {
    @include vp-1023 {
      background: rgba(255, 255, 255, 0.15);
      border: none;
      border-radius: size(40px);
      color: $color-default-white;
      flex: 1 0 auto;
      font-size: size(15px);
      line-height: size(20px);
      margin: 0 size(5px) 0 0;
      padding: size(22.5px) size(20px);
      transition: all $trans-default;
      transform: translate3d(0, 0, 0);
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-light-slate-blue;
      }
    }
  }

  &__buttons-link {
    @include vp-1023 {
      align-items: center;
      background: rgba(255, 255, 255, 0.15);
      border-radius: 50%;
      display: flex;
      height: size(66px);
      justify-content: center;
      transition: all $trans-default;
      transform: translate3d(0, 0, 0);
      width: size(66px);

      svg {
        height: size(36px);
        width: size(36px);
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-light-slate-blue;
      }
    }
  }
}
