.hero {
  margin: 0;
  padding: size(75px) 0 0;

  @include vp-767 {
    padding: size(45px) 0 0;
  }

  &__title {
    font-size: size(60px);
    font-weight: 400;
    line-height: size(65px);
    margin: 0 0 size(75px) 0;
    padding: 0 0 0 size(20px);
    white-space: break-spaces;

    @include vp-1023 {
      font-size: size(50px);
      line-height: size(55px);
      margin: 0 0 size(55px) 0;
      padding: 0;
    }

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
      margin: 0 0 size(45px) 0;
    }

    .desktop-only {
      @include vp-1023 {
        display: none;
      }
    }

    .mobile-only {
      display: none;

      @include vp-767 {
        display: block;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: size(-40px) size(-20px) 0;
    padding: 0;

    @include vp-1023 {
      margin: size(-50px) size(-30px) 0;
    }

    @include vp-767 {
      margin: size(-45px) size(-20px) 0;
    }
  }

  &__item {
    display: flex;
    flex-grow: 1;
    padding: size(40px) size(40px) 0;

    @include vp-1023 {
      padding: size(50px) size(30px) 0;
    }

    @include vp-767 {
      padding: size(45px) size(20px) 0;
    }
  }

  &__achievements {
    cursor: pointer;
    font-size: size(60px);
    line-height: size(65px);
    position: relative;
    transition: color $trans-default;

    @include vp-1150 {
      font-size: size(50px);
      line-height: size(55px);
    }

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
    }

    &::after {
      content: "";
      left: size(-20px);
      height: calc(100% + #{size(10px)});
      position: absolute;
      top: 0;
      width: calc(100% + #{size(40px)});
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        color: $color-light-slate-blue;

        .hero__underline {
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  &__number {
    display: block;
  }

  &__underline {
    display: inline-block;
    position: relative;

    &::before {
      background-color: rgba($color-default-black, 0.15);
      bottom: size(-10px);
      content: "";
      height: size(3px);
      left: 0;
      position: absolute;
      transition: opacity $trans-default;
      width: 100%;

      @include vp-767 {
        bottom: size(-5px);
        height: size(2px);
      }
    }
  }

  &__btn-wrapper {
    margin-top: 5.06667rem;
    margin-left: 1.33333rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.33333rem;

    @include vp-767 {
      margin-left: 0;
      width: 100%;
    }
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 20.66667rem;
    min-height: 4.33333rem;
    border-radius: 2.66667rem;

    @include vp-767 {
      width: 100%;
    }
  }
}
