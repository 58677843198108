.inner-simple-cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  margin: size(40px) 0 0;
  padding: 0;

  &--margin-big {
    margin: size(65px) 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }

    @include vp-767 {
      margin: size(40px) 0 0;
    }
  }

  &--margin-middle {
    margin: size(40px) 0 0;
  }

  &--margin-small {
    margin: size(20px) 0 0;
  }

  &--mobile-margin-middle {
    @include vp-767 {
      margin: size(40px) 0 0;
    }
  }

  &--no-margin {
    margin: 0;
  }

  &--margin-top-65-40-40 {
    margin: size(65px) 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }

    @include vp-767 {
      margin: size(40px) 0 0;
    }
  }

  &--half {
    grid-template-columns: 1fr 1fr;
  }

  &:only-child {
    margin: 0;
  }

  @include vp-1023 {
    grid-template-columns: 1fr 1fr;
  }

  @include vp-767 {
    grid-template-columns: 1fr;
  }

  &--height-125 .inner-simple-cards__item {
    min-height: size(125px);
  }

  &--height-150 .inner-simple-cards__item {
    min-height: size(150px);
  }

  &--height-165 .inner-simple-cards__item {
    min-height: size(165px);
  }

  &--height-170 .inner-simple-cards__item {
    min-height: size(170px);
  }

  &--text-bottom p {
    margin: auto 0 0;
    white-space: break-spaces;
  }

  &__item {
    position: relative;
    background-color: $color-default-white;
    border-radius: size(20px);
    display: flex;
    flex-direction: column;
    min-height: size(150px);
    padding: size(20px);

    &--bottom-text p {
      margin: auto 0 0;
    }
  }

  &__card-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    padding: size(20px);
    display: flex;
    flex-direction: column;
    border-radius: size(20px);
    transition: all $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        box-shadow: 0 20px 40px 0 $color-black-opacity-5;
      }
    }
  }

  &__heading {
    font-size: size(20px);
    font-weight: 400;
    line-height: size(25px);
    margin: 0 0 size(10px) 0;
  }

  &__description {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);
    margin: 0;
    padding: 0;
  }

  &__description + .inner-simple-cards__description {
    margin: size(10px) 0 0;
  }

  &__link {
    color: $color-light-slate-blue;
    text-decoration: underline;
    text-decoration-color: rgba($color: $color-light-slate-blue, $alpha: 0.2);
    text-underline-offset: size(5px);
    text-decoration-thickness: size(1px);
    transition: text-decoration-color $trans-default;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        text-decoration-color: rgba($color: $color-light-slate-blue, $alpha: 1);
      }
    }
  }
}
