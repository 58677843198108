.inner-simple-table {
  padding: size(20px) 0 0;

  &--no-padding {
    padding: 0;
  }

  &--big-padding {
    padding: size(150px) 0 0;

    @include vp-1023 {
      padding: size(100px) 0 0;
    }
  }

  &--gap-20-0-0 .inner-simple-table__list {
    gap: size(20px) 0;

    @include vp-1023 {
      gap: 0;
    }

    @include vp-767 {
      gap: 0;
    }
  }

  &__value p:not(:last-child) {
    margin-bottom: size(20px);
  }

  &--margin-10-10-5 .inner-simple-table__value p:not(:last-child) {
    margin: 0 0 size(10px) 0;

    @include vp-1023 {
      margin: 0 0 size(10px) 0;
    }

    @include vp-767 {
      margin: 0 0 size(5px) 0;
    }
  }

  &--margin-25-25-5 .inner-simple-table__value p:not(:last-child) {
    margin: 0 0 size(25px) 0;

    @include vp-1023 {
      margin: 0 0 size(25px) 0;
    }

    @include vp-767 {
      margin: 0 0 size(5px) 0;
    }
  }

  &--font-small {
    .inner-simple-table__value {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  &--tablet-font-middle {
    .inner-simple-table__key {
      @include vp-1023 {
        font-size: size(25px);
        line-height: size(30px);
      }

      @include vp-767 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }

    .inner-simple-table__value {
      @include vp-1023 {
        font-size: size(20px);
        line-height: size(25px);
      }

      @include vp-767 {
        font-size: size(15px);
        line-height: size(20px);
      }
    }
  }

  &--margin-top-small {
    margin: size(20px) 0 0;

    @include vp-1023 {
      margin: size(10px) 0 0;
    }
  }

  &--margin-top-middle {
    margin: size(40px) 0 0;

    @include vp-1023 {
      margin: size(20px) 0 0;
    }
  }

  &--margin-top-big {
    margin: size(65px) 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }
  }

  &--desktop-margin-top {
    margin: size(65px) 0 0;
  }

  &--mobile-margin-top {
    @include vp-1023 {
      margin: size(10px) 0 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    align-items: flex-start;
    border-top: size(1px) solid rgba(0, 0, 0, 0.15);
    display: flex;
    padding: size(19px) 0 size(20px) 0;

    &--no-border {
      border: none;
    }

    @include vp-1023 {
      border: none;
      flex-direction: column;
      padding: 0 0 size(20px) 0;
    }

    &:only-child {
      padding: 0;
    }

    &:first-child {
      border-top: none;
    }

    &:last-child {
      @include vp-1023 {
        padding: 0;
      }
    }
  }

  &--height-140 .inner-simple-table__item {
    min-height: size(140px);

    @include vp-767 {
      min-height: auto;
    }
  }

  &--height-120 .inner-simple-table__item {
    min-height: size(120px);

    @include vp-767 {
      min-height: auto;
    }
  }

  &--height-small .inner-simple-table__item {
    min-height: size(90px);

    @include vp-767 {
      min-height: auto;
    }
  }

  &--height-middle .inner-simple-table__item {
    min-height: size(100px);

    @include vp-767 {
      min-height: auto;
    }
  }

  &--height-big .inner-simple-table__item {
    min-height: size(125px);

    @include vp-767 {
      min-height: auto;
    }
  }

  &__key {
    font-size: size(20px);
    flex: 0 0 auto;
    line-height: size(25px);
    padding: 0 size(20px) 0 size(20px);
    width: size(420px);

    @include vp-1023 {
      margin: size(10px) 0;
      padding: 0;
      width: 100%;
    }
  }

  &__value {
    font-size: size(20px);
    line-height: size(25px);
    padding: 0 size(25px) 0 size(20px);
    margin: 0;
    min-height: size(50px);
    white-space: break-spaces;

    @include vp-1023 {
      min-height: unset;
      padding: 0;
      font-size: size(15px);
      line-height: size(20px);
    }

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }

    p {
      //margin: 0;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }

  & br.only-desktop {
    @include vp-1023 {
      display: none;
    }
  }

  & br.only-mobile {
    display: none;

    @include vp-767 {
      display: initial;
    }
  }
}
