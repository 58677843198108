.footer {
  padding: size(40px) 0 size(80px);

  text-align: center;

  background-color: $color-default-black;

  &__copyright {
    white-space: pre-line;
    max-width: size(280px);
    margin: 0 auto size(35px);

    font-size: size(15px);
    line-height: size(20px);
    text-align: center;
    color: $color-white-smoke;
  }

  &__link {
    border: size(1px) solid rgba($color-default-white, 0.15);
    border-radius: size(20px);
    color: $color-light-slate-blue;
    display: inline-flex;
    font-size: size(15px);
    line-height: size(20px);
    padding: size(9px) size(15px);
    transition: all $trans-default;
    cursor: pointer;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover, {
        border-color: $color-transparent;
        color: $color-default-black;
        background-color: $color-light-slate-blue;
      }
    }
  }
}
