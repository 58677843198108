.products {
  padding: size(150px) 0 0;

  @include vp-1023 {
    padding: size(100px) 0 0;
  }

  &__content {
    display: flex;
    margin: 0 0 size(65px) 0;

    @include vp-767 {
      flex-direction: column;
      margin: 0;
    }
  }

  &__aside.aside {
    @include vp-767 {
      padding: 0;
    }
  }

  &__text {
    font-size: size(40px);
    line-height: size(45px);
    max-width: size(790px);
    padding: 0 0 0 size(20px);
    white-space: break-spaces;

    @include vp-1023 {
      font-size: size(30px);
      line-height: size(35px);
      padding: 0;
    }

    @include vp-767 {
      order: -1;
      margin: 0 0 size(40px) 0;
      font-size: size(40px);
      line-height: size(45px);
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(45px) 0;
      }
    }
  }

  &__list {
    display: grid;
    gap: size(20px);
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp-1023 {
      grid-template-columns: 1fr 1fr;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
  }

  &__link {
    align-items: center;
    background-color: $color-default-white;
    border-radius: size(20px);
    display: flex;
    flex-direction: column;
    padding: size(20px);
    position: relative;
    transition: all $trans-default;
    width: 100%;

    @include vp-1023 {
      padding: size(20px) size(10px);
    }

    &--radius-all {
      border-radius: size(110px);

      @include vp-1023 {
        border-radius: size(80px);
      }

      @include vp-767 {
        border-radius: size(110px);
      }
    }

    &--radius-right {
      border-radius: size(20px) size(110px) size(110px) size(20px);

      @include vp-1023 {
        border-radius: size(20px) size(80px) size(80px) size(20px);
      }

      @include vp-767 {
        border-radius: size(20px) size(110px) size(110px) size(20px);
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover {
        box-shadow: 0 size(20px) size(40px) rgba(0, 0, 0, 0.05);
      }
    }

    svg {
      height: size(80px);
      width: size(80px);
    }
  }

  &__picture {
    border-radius: size(10px);
    height: size(80px);
    overflow: hidden;
    width: size(80px);

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__title {
    font-size: size(20px);
    font-weight: 400;
    line-height: size(25px);
    margin: size(20px) 0 size(15px) 0;
  }

  &__description {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);
    margin: 0;
    max-width: size(360px);
    text-align: center;

    p {
      margin: 0;
    }
  }
}
